import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

interface StoryCardProps {
  story: {
    id: string;
    title: string;
    summary: string;
    createdAt: string;
  };
}

const StoryCard: React.FC<StoryCardProps> = ({ story }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-white rounded-lg shadow-md overflow-hidden"
    >
      <div className="p-4">
        <h3 className="text-xl font-semibold text-purple-600 mb-2">{story.title}</h3>
        <p className="text-gray-600 mb-4 line-clamp-3">{story.summary}</p>
        <div className="flex justify-between items-center">
          <span className="text-sm text-gray-500">
            {new Date(story.createdAt).toLocaleDateString()}
          </span>
          <Link
            to={`/story/${story.id}`}
            className="text-purple-600 hover:text-purple-800 font-semibold"
          >
            Read More
          </Link>
        </div>
      </div>
    </motion.div>
  );
};

export default StoryCard;