import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
} from '../../hooks/reduxHooks';
import { googleAuth } from '../../store/slices/authSlice';
import { GoogleLogin } from '@react-oauth/google';

interface AuthFormProps {
  mode: 'signup' | 'signin';
}

function AuthForm({ mode }: AuthFormProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoading, error, isAuthenticated } = useAppSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate, location.state]);

  const handleGoogleAuth = async (credentialResponse: any) => {
    try {
      const result = await dispatch(
        googleAuth({
          credential: credentialResponse.credential,
          mode,
        })
      ).unwrap();

      const intendedDestination =
        location.state?.from || '/dashboard';

      if (!result.isStoryPreferencesSet) {
        navigate('/preferences', {
          state: { from: intendedDestination },
        });
      } else {
        navigate(intendedDestination, { replace: true });
      }
    } catch (error) {
      console.error(`Error during Google ${mode}:`, error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md flex flex-col items-center justify-center space-y-6">
        <h2 className="text-2xl font-bold text-purple-600 text-center">
          Continue with Google
        </h2>
        {error && <p className="text-red-500">{error}</p>}
        <GoogleLogin
          onSuccess={handleGoogleAuth}
          onError={() => console.log('Authentication Failed')}
        />
        {isLoading && <p>Loading...</p>}
      </div>
    </div>
  );
}

export default AuthForm;
