import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

interface PaymentState {
  sessionId: string | null;
  redirectUrl: string | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: PaymentState = {
  sessionId: null,
  redirectUrl: null,
  isLoading: false,
  error: null,
};

export const createCheckoutSession = createAsyncThunk(
  'payment/createCheckoutSession',
  async (priceId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        '/payments/checkout',
        { priceId }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'An error occurred'
      );
    }
  }
);

export const completePayment = createAsyncThunk(
  'payment/completePayment',
  async (sessionId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/payments/complete-payment?session_id=${sessionId}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'An error occurred'
      );
    }
  }
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCheckoutSession.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCheckoutSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessionId = action.payload.sessionId;
      })
      .addCase(createCheckoutSession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(completePayment.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(completePayment.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(completePayment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default paymentSlice.reducer;
