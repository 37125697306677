import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { AchievementType } from '../../types/achievement';

export const fetchAchievements = createAsyncThunk(
  'achievements/fetchAchievements',
  async () => {
    const response = await axiosInstance.get('/achievements');
    return response.data.data;
  }
);

const achievementSlice = createSlice({
  name: 'achievements',
  initialState: {
    list: [] as AchievementType[],
    isLoading: false,
    error: null as string | null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAchievements.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAchievements.fulfilled, (state, action) => {
        state.isLoading = false;
        state.list = action.payload;
      })
      .addCase(fetchAchievements.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.error.message || 'Failed to fetch achievements';
      });
  },
});

export default achievementSlice.reducer;
