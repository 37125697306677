import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/reduxHooks';
import { updateUser } from '../../store/slices/userSlice';
import { UserStoryPreferencesType } from '../../types/user';
import { fetchNarrationVoices } from '../../store/slices/narrationVoiceSlice';
import Spinner from '../common/Spinner';

interface UserPreferencesProps {
  onClose?: () => void;
}

const UserPreferences: React.FC<UserPreferencesProps> = ({
  onClose,
}) => {
  const location = useLocation();
  const {
    id: userId,
    storyPreferences,
    isLoading,
    error,
  } = useAppSelector((state) => state.user);
  const [preferences, setPreferences] =
    useState<UserStoryPreferencesType>(
      storyPreferences || {
        preferredGenres: [],
        storyLength: 'medium',
        tonePreference: 'humorous',
        ageGroup: 'preschoolers',
        favouriteThemes: [],
        learningGoals: [],
        narrationVoiceId: null,
      }
    );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { voices } = useAppSelector((state) => state.narrationVoice);

  useEffect(() => {
    dispatch(fetchNarrationVoices());
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setPreferences((prev: UserStoryPreferencesType) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleArrayChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setPreferences((prev: UserStoryPreferencesType) => ({
      ...prev,
      [name]: value.split(',').map((item) => item.trim()),
    }));
  };

  const handleNarrationVoiceChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const narrationVoiceId = e.target.value;
    setPreferences((prev: UserStoryPreferencesType) => ({
      ...prev,
      narrationVoiceId: {
        id: narrationVoiceId,
      },
    }));
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    try {
      await dispatch(
        updateUser({
          userId,
          userData: { storyPreferences: preferences, isStoryPreferencesSet: true },
        })
      ).unwrap();

      if (onClose) {
        onClose();
      } else {
        const intendedDestination = location.state?.from || '/dashboard';
        navigate(intendedDestination, { replace: true });
      }
      // if (location.pathname === '/preferences') {
      //   navigate('/dashboard');
      // } else if (onClose) {
      //   onClose();
      // }
    } catch (error) {
      console.error('Failed to update preferences:', error);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-purple-600">
        Story Preferences
      </h2>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="preferredGenres"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Preferred Genres (comma-separated):
          </label>
          <input
            type="text"
            id="preferredGenres"
            name="preferredGenres"
            value={preferences.preferredGenres.join(', ')}
            onChange={handleArrayChange}
            placeholder="e.g., Fantasy, Adventure, Mystery"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="storyLength"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Story Length:
          </label>
          <select
            id="storyLength"
            name="storyLength"
            value={preferences.storyLength}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          >
            <option value="short">Short</option>
            <option value="medium">Medium</option>
            <option value="long">Long</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="tonePreference"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Tone Preference:
          </label>
          <select
            id="tonePreference"
            name="tonePreference"
            value={preferences.tonePreference}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          >
            <option value="humorous">Humorous</option>
            <option value="serious">Serious</option>
            <option value="inspirational">Inspirational</option>
            <option value="whimsical">Whimsical</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="ageGroup"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Age Group:
          </label>
          <select
            id="ageGroup"
            name="ageGroup"
            value={preferences.ageGroup}
            onChange={handleChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          >
            <option value="toddlers">Toddlers (1-2 years)</option>
            <option value="preschoolers">
              Preschoolers (3-5 years)
            </option>
            <option value="early readers">
              Early Readers (6-8 years)
            </option>
            <option value="older children">
              Older Children (9-12 years)
            </option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="favouriteThemes"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Favourite Themes (comma-separated):
          </label>
          <input
            type="text"
            id="favouriteThemes"
            name="favouriteThemes"
            value={preferences.favouriteThemes.join(', ')}
            onChange={handleArrayChange}
            placeholder="e.g., Friendship, Nature, Space"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="learningGoals"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Learning Goals (comma-separated):
          </label>
          <input
            type="text"
            id="learningGoals"
            name="learningGoals"
            value={preferences.learningGoals.join(', ')}
            onChange={handleArrayChange}
            placeholder="e.g., Vocabulary, Math, Science"
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="narrationVoice"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Narration Voice:
          </label>
          <select
            id="narrationVoice"
            name="narrationVoice"
            value={preferences.narrationVoiceId?.id || ''}
            onChange={handleNarrationVoiceChange}
            className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-purple-500"
          >
            <option value="">Select a voice</option>
            {voices.map((voice) => (
              <option key={voice.id} value={voice.id}>
                {`${voice.accent} - ${voice.gender}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-between">
          <button
            type="submit"
            className="bg-purple-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-purple-700 transition duration-300"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save Preferences'}
          </button>
          {onClose && (
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 text-gray-700 font-bold py-2 px-4 rounded-lg hover:bg-gray-400 transition duration-300"
            >
              Cancel
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default UserPreferences;
