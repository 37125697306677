import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/reduxHooks';
import { logout } from '../../store/slices/authSlice';
import { resetStory } from '../../store/slices/storySlice';
import { resetStoryParts } from '../../store/slices/storyPartSlice';
import { FaBars, FaTimes } from 'react-icons/fa';

function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  const { profilePicture } = useAppSelector((state) => state.user);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    dispatch(logout());
    dispatch({ type: 'auth/logout' });
    setIsMenuOpen(false);
    setIsMobileMenuOpen(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogoClick = (e: React.MouseEvent) => {
    if (isAuthenticated) {
      e.preventDefault();
      navigate('/dashboard');
    }
  };

  const handleCreateMagic = () => {
    dispatch(resetStory());
    dispatch(resetStoryParts());
    navigate('/create-story');
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <header className="bg-purple-600 text-white py-4 px-4 md:px-8">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          <Link
            to={isAuthenticated ? '/dashboard' : '/'}
            className="flex items-center"
            onClick={handleLogoClick}
          >
            <img
              src="/storybuddyai_logo.png"
              alt="StoryBuddyAI Logo"
              className="h-8 md:h-10 mr-2"
            />
            <span className="text-sm md:text-xl font-bold">StoryBuddyAI</span>
          </Link>
          <nav className="flex items-center">
            {isAuthenticated ? (
              <>
                <button
                  onClick={handleCreateMagic}
                  className="hidden md:flex mr-4 bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition duration-300 items-center"
                >
                  <span className="mr-2">✨</span>
                  Create Magic
                </button>
                <div
                  className="relative hidden md:block"
                  ref={menuRef}
                >
                  <button
                    onClick={toggleMenu}
                    className="flex items-center focus:outline-none"
                    aria-label="User menu"
                  >
                    <img
                      src={profilePicture || '/default-avatar.png'}
                      alt="User"
                      className="w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-white"
                    />
                  </button>
                  {isMenuOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-xl py-2 z-10">
                      <Link
                        to="/dashboard"
                        className="block px-4 py-2 text-gray-800 hover:bg-purple-100"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        Dashboard
                      </Link>
                      <Link
                        to="/stories"
                        className="block px-4 py-2 text-gray-800 hover:bg-purple-100"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        My Stories
                      </Link>
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-gray-800 hover:bg-purple-100"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        My Profile
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-purple-100"
                      >
                        Logout
                      </button>
                    </div>
                  )}
                </div>
                <button
                  onClick={toggleMobileMenu}
                  className="md:hidden text-white focus:outline-none ml-4"
                  aria-label="Mobile menu"
                >
                  {isMobileMenuOpen ? (
                    <FaTimes size={24} />
                  ) : (
                    <FaBars size={24} />
                  )}
                </button>
              </>
            ) : (
              <Link
                to="/signin"
                className="bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition duration-300"
              >
                Join the Fun
              </Link>
            )}
          </nav>
        </div>
        {isAuthenticated && isMobileMenuOpen && (
          <div className="md:hidden mt-4">
            <Link
              to="/create-story"
              className="block bg-white text-purple-600 px-4 py-2 rounded-full hover:bg-purple-100 transition duration-300 mb-2"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <span className="mr-2">✨</span>
              Create Magic
            </Link>
            <Link
              to="/dashboard"
              className="block text-white hover:bg-purple-500 px-4 py-2"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              Dashboard
            </Link>
            <Link
              to="/profile"
              className="block text-white hover:bg-purple-500 px-4 py-2"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              My Profile
            </Link>
            <Link
              to="/stories"
              className="block text-white hover:bg-purple-500 px-4 py-2"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              My Stories
            </Link>
            <button
              onClick={handleLogout}
              className="block w-full text-left text-white hover:bg-purple-500 px-4 py-2"
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
