import React, { useState } from 'react';

interface ContinuationFormProps {
  onSubmit: (continuationPrompt: string) => void;
  isLoading: boolean;
}

const ContinuationForm: React.FC<ContinuationFormProps> = ({ onSubmit, isLoading }) => {
  const [continuationPrompt, setContinuationPrompt] = useState('');
  const [isAdvancedPrompt, setIsAdvancedPrompt] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsAnimating(true);
    onSubmit(continuationPrompt);
    setTimeout(() => {
      setIsAnimating(false);
      setContinuationPrompt('');
    }, 3000);
  };

  return (
    <div className="bg-purple-50 p-6 rounded-lg shadow-inner relative overflow-hidden">
      <h4 className="text-2xl font-semibold mb-4 text-purple-700">What happens next?</h4>

      <div className="flex items-center mb-4">
        <button
          onClick={() => setIsAdvancedPrompt(false)}
          className={`mr-4 px-4 py-2 rounded-full ${
            !isAdvancedPrompt ? 'bg-purple-600 text-white' : 'bg-purple-100 text-purple-600'
          }`}
        >
          Quick Continue
        </button>
        <button
          onClick={() => setIsAdvancedPrompt(true)}
          className={`px-4 py-2 rounded-full ${
            isAdvancedPrompt ? 'bg-purple-600 text-white' : 'bg-purple-100 text-purple-600'
          }`}
        >
          Add Details
        </button>
      </div>

      <form onSubmit={handleSubmit} className="relative">
        {isAdvancedPrompt ? (
          <div className="mb-4">
            <textarea
              id="continuationPrompt"
              value={continuationPrompt}
              onChange={(e) => setContinuationPrompt(e.target.value)}
              className="w-full px-4 py-3 text-lg text-gray-700 border-2 border-purple-300 rounded-lg focus:outline-none focus:border-purple-500 transition duration-300"
              rows={3}
              placeholder="Add your ideas for the next part of the story..."
              required
              minLength={1}
            />
          </div>
        ) : (
          <div className="mb-4 flex flex-wrap gap-2">
            {['Exciting twist!', 'New character appears', 'Magical event', 'Unexpected challenge'].map((suggestion) => (
              <button
                key={suggestion}
                type="button"
                onClick={() => setContinuationPrompt(suggestion)}
                className={`px-3 py-2 rounded-full text-sm ${
                  continuationPrompt === suggestion
                    ? 'bg-purple-600 text-white'
                    : 'bg-purple-100 text-purple-600 hover:bg-purple-200'
                }`}
              >
                {suggestion}
              </button>
            ))}
          </div>
        )}
        <button
          type="submit"
          className="w-full bg-purple-600 text-white text-xl font-bold py-3 px-6 rounded-lg hover:bg-purple-700 transition duration-300 transform hover:scale-105"
          disabled={isLoading || isAnimating}
        >
          {isLoading ? 'Adding More Magic...' : 'Continue the Adventure'}
        </button>

        {isAnimating && (
          <div className="absolute inset-0 flex items-center justify-center bg-purple-100 bg-opacity-75 rounded-lg z-10">
            <div className="text-6xl animate-bounce">✨</div>
          </div>
        )}
      </form>
    </div>
  );
};

export default ContinuationForm;