import React, { useState } from 'react';

interface FloatingActionButtonProps {
  onClick: () => void;
  className?: string;
  showTooltip: boolean;
}

const FloatingActionButton: React.FC<FloatingActionButtonProps> = ({
  onClick,
  className = '',
  showTooltip,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  return (
    <div className={`fixed z-50 ${className}`}>
      <button
        onClick={onClick}
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        className="bg-purple-600 text-white rounded-full p-4 shadow-lg hover:bg-purple-700 transition duration-300 transform hover:scale-110"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </button>
      {showTooltip && isTooltipVisible && (
        <div className="absolute bottom-full right-0 mb-2 bg-purple-800 text-white py-2 px-4 rounded-lg text-sm whitespace-nowrap">
          Start a New Story
        </div>
      )}
    </div>
  );
};

export default FloatingActionButton;
