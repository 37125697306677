import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

interface StoryState {
  id: string;
  stories: any[];
  totalStories: number;
  currentStory: string;
  title: string;
  summary: string;
  createdAt: string;
  isLoading: boolean;
  error: string | null;
  isCompleted: boolean;
  numberOfParts: number;
}

const initialState: StoryState = {
  id: '',
  stories: [],
  totalStories: 0,
  currentStory: '',
  title: '',
  summary: '',
  createdAt: '',
  isLoading: false,
  error: null,
  isCompleted: false,
  numberOfParts: 0,
};

export const generateStory = createAsyncThunk(
  'story/create',
  async (
    {
      prompt,
    }: {
      prompt: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post('/stories', {
        prompt,
      });
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message ||
          'An error occurred while generating the story'
      );
    }
  }
);

export const continueStory = createAsyncThunk(
  'story/continue',
  async (
    {
      storyId,
      continuationPrompt,
    }: {
      storyId: string;
      continuationPrompt: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        `/stories/${storyId}/continue`,
        {
          continuationPrompt,
        }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message ||
          'An error occurred while continuing the story'
      );
    }
  }
);

export const fetchUserStories = createAsyncThunk(
  'story/fetchUserStories',
  async (
    {
      userId,
      page = 1,
      limit = 10,
    }: { userId: string; page?: number; limit?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.get(
        `/users/${userId}/stories`,
        {
          params: { page, limit },
        }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message ||
          'An error occurred while fetching your stories'
      );
    }
  }
);

export const fetchStoryWithParts = createAsyncThunk(
  'story/fetchStoryWithParts',
  async (storyId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/stories/${storyId}`);
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message ||
          'An error occurred while fetching the story'
      );
    }
  }
);

export const updateStory = createAsyncThunk(
  'story/update',
  async (
    {
      storyId,
      isCompleted,
    }: {
      storyId: string;
      isCompleted: boolean;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.patch(
        `/stories/${storyId}`,
        {
          isCompleted,
        }
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message ||
          'An error occurred while updating the story'
      );
    }
  }
);

const storySlice = createSlice({
  name: 'story',
  initialState,
  reducers: {
    resetStory: (state) => {
      return { ...initialState };
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserStories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stories = action.payload.stories;
        state.totalStories = action.payload.total;
        state.numberOfParts = action.payload.numberOfParts;
      })
      .addCase(fetchUserStories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(generateStory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateStory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.id = action.payload.id;
        state.title = action.payload.title;
        state.summary = action.payload.summary;
        state.currentStory = action.payload.content;
        state.isCompleted = false;
      })
      .addCase(generateStory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(continueStory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(continueStory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.title = action.payload.title;
        state.summary = action.payload.summary;
        state.currentStory = action.payload.content;
      })
      .addCase(continueStory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(fetchStoryWithParts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStoryWithParts.fulfilled, (state, action) => {
        state.id = action.payload.id;
        state.title = action.payload.title;
        state.summary = action.payload.summary;
        state.createdAt = action.payload.createdAt;
        state.isLoading = false;
      })
      .addCase(fetchStoryWithParts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateStory.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateStory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCompleted = action.payload.isCompleted;
      })
      .addCase(updateStory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetStory, resetError } = storySlice.actions;
export default storySlice.reducer;
