export interface PlanOption {
  name: string;
  description: string;
  price: number;
  priceId: string;
  interval: 'month' | 'year';
  features: string[];
}

export interface Plan {
  name: string;
  description: string;
  price: number;
  paymentProcessorPriceId: string;
  interval: 'month' | 'year';
  features: string[];
}

export const plans: Plan[] = [
  {
    name: 'Tale Taster Monthly',
    description: 'Perfect for families who want to try out the app',
    price: 0,
    paymentProcessorPriceId: 'price_1Q9s9RHXYg9JCkoDlySqWg5b',
    interval: 'month',
    features: ['1 story per month', 'Text-only stories'],
  },
  {
    name: 'Tale Taster Yearly',
    description: 'Perfect for families who want to try out the app',
    price: 0,
    paymentProcessorPriceId: 'price_1Q9sBeHXYg9JCkoDCUxeVRbT',
    interval: 'year',
    features: ['1 story per month', 'Text-only stories'],
  },
  {
    name: 'Story Explorer Monthly',
    description: 'Perfect for casual storytellers',
    price: 7.99,
    paymentProcessorPriceId: 'price_1Q9sDKHXYg9JCkoDLDcDwFZf',
    interval: 'month',
    features: [
      '10 stories per month',
      'Voice narration for 5 stories/month',
      'Basic illustrations',
      'Basic parental controls',
    ],
  },
  {
    name: 'Story Explorer Yearly',
    description: 'Perfect for casual storytellers',
    price: 79.9,
    paymentProcessorPriceId: 'price_1Q9sFZHXYg9JCkoDcN3KofKX',
    interval: 'year',
    features: [
      '10 stories per month',
      'Voice narration for 5 stories/month',
      'Basic illustrations',
      'Basic parental controls',
    ],
  },
  {
    name: 'Adventure Seeker Monthly',
    description: 'Ideal for regular storytelling enthusiasts',
    price: 14.99,
    paymentProcessorPriceId: 'price_1Q9sH2HXYg9JCkoD1PKT56l0',
    interval: 'month',
    features: [
      '30 stories per month',
      'Interactive stories with choices',
      'Voice narration for 15 stories/month',
      'Basic illustrations',
      'Basic parental controls',
    ],
  },
  {
    name: 'Adventure Seeker Yearly',
    description: 'Ideal for regular storytelling enthusiasts',
    price: 149.9,
    paymentProcessorPriceId: 'price_1Q9sHvHXYg9JCkoDaX73TFCG',
    interval: 'year',
    features: [
      '30 stories per month',
      'Interactive stories with choices',
      'Voice narration for 15 stories/month',
      'Basic illustrations',
      'Basic parental controls',
    ],
  },
  {
    name: 'Imagination Hero Monthly',
    description: 'For the ultimate storytelling experience',
    price: 24.99,
    paymentProcessorPriceId: 'price_1Q9sJoHXYg9JCkoDmSMMw2BV',
    interval: 'month',
    features: [
      '100 stories per month',
      'Interactive stories with choices',
      'Voice narration for all stories',
      'Premium illustrations',
      'Advanced parental controls',
      'Multi-language support',
      'Priority support',
    ],
  },
  {
    name: 'Imagination Hero Yearly',
    description: 'For the ultimate storytelling experience',
    price: 249.9,
    paymentProcessorPriceId: 'price_1Q9sKVHXYg9JCkoDYVMIXrsr',
    interval: 'year',
    features: [
      '100 stories per month',
      'Interactive stories with choices',
      'Voice narration for all stories',
      'Premium illustrations',
      'Advanced parental controls',
      'Multi-language support',
      'Priority support',
    ],
  },
];
