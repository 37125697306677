import React, { useEffect, useState } from 'react';
import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/reduxHooks';
import { fetchUserStories } from '../../store/slices/storySlice';
import { fetchAchievements } from '../../store/slices/achievementSlice';
import { motion } from 'framer-motion';
import {
  FaBook,
  FaPencilAlt,
  FaTrophy,
  // FaChartLine,
  FaMagic,
} from 'react-icons/fa';
import StoryCard from './StoryCard';
import AchievementCard from './AchievementCard';
import StatCard from './StatCard';
import Spinner from '../common/Spinner';
import { Link } from 'react-router-dom';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    id: userId,
    name,
    achievements: userAchievements,
    wordsGenerated,
    storyCount,
  } = useAppSelector((state) => state.user);
  const { stories } = useAppSelector((state) => state.story);
  const { list: allAchievements } = useAppSelector(
    (state) => state.achievements
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadDashboardData = async () => {
      await Promise.all([
        dispatch(fetchUserStories({ userId, page: 1, limit: 5 })),
        dispatch(fetchAchievements()),
      ]);
      setIsLoading(false);
    };

    if (userId) {
      loadDashboardData();
    }
  }, [dispatch, userId]);

  const filteredAchievements = allAchievements.filter((achievement) =>
    userAchievements.includes(achievement.key)
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="text-4xl font-bold text-purple-600 mb-8"
      >
        Welcome back, {name?.split(' ')[0]}!
      </motion.h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard
          icon={FaBook}
          title="Tales Created"
          value={storyCount}
        />
        <StatCard
          icon={FaPencilAlt}
          title="Words Woven"
          value={wordsGenerated}
        />
        <StatCard
          icon={FaTrophy}
          title={filteredAchievements.length === 1 ? "Achievement" : "Achievements"}
          value={filteredAchievements.length}
        />
        {/* <StatCard icon={FaChartLine} title="Reading Streak" value={readingStreak || 0} /> */}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <h2 className="text-2xl font-semibold text-purple-600 mb-4">
          Your Recent Stories
        </h2>
        {stories.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {stories.slice(0, 6).map((story) => (
              <StoryCard key={story.id} story={story} />
            ))}
          </div>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <p className="text-lg text-gray-600 mb-4">
              You haven't created any stories yet. Let's start your magical
              journey!
            </p>
            <Link
              to="/create-story"
              className="inline-flex items-center bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300"
            >
              <FaMagic className="mr-2" /> Create Your First Story
            </Link>
          </div>
        )}
      </motion.div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
        className="mt-8"
      >
        <h2 className="text-2xl font-semibold text-purple-600 mb-4">
          Your Achievements
        </h2>
        {filteredAchievements.length > 0 ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {filteredAchievements.slice(0, 4).map((achievement) => (
              <AchievementCard
                key={achievement.id}
                achievement={achievement}
              />
            ))}
          </div>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-md text-center">
            <p className="text-lg text-gray-600 mb-4">
              You haven't unlocked any achievements yet. Keep creating stories
              to earn exciting rewards!
            </p>
            <Link
              to="/stories"
              className="inline-flex items-center bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300"
            >
              <FaBook className="mr-2" /> View Your Stories
            </Link>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Dashboard;
