import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { fetchStoryPartsImageStatus } from '../store/slices/storyPartSlice';
import { CHECK_INTERVALS } from '../constants/audio';

const useImageStatusCheck = (
  storyId: string | undefined,
  parts: any[]
) => {
  const dispatch = useAppDispatch();
  const [checkCounts, setCheckCounts] = useState<
    Record<string, number>
  >({});
  const [imageCheckErrors, setImageCheckErrors] = useState<
    Record<string, string | null>
  >({});
  const imageStatus = useAppSelector(
    (state) => state.storyPart.imageStatus
  );

  useEffect(() => {
    if (!storyId || parts.length === 0) return;

    const checkImageStatus = (partId: string) => {
      dispatch(
        fetchStoryPartsImageStatus({
          storyId,
          parts: [{ id: partId }],
        })
      )
        .unwrap()
        .then(() => {
          setCheckCounts((prev) => ({
            ...prev,
            [partId]: (prev[partId] || 0) + 1,
          }));
        })
        .catch((error) => {
          console.error('Error fetching image status:', error);
          setImageCheckErrors((prev) => ({
            ...prev,
            [partId]:
              'Unable to fetch image status. Please try again later.',
          }));
        });
    };

    const unfinishedParts = parts.filter(
      (part) =>
        !imageStatus[part.id]?.isGenerated &&
        (checkCounts[part.id] || 0) < CHECK_INTERVALS.length
    );

    if (unfinishedParts.length === 0) return;

    const timeouts = unfinishedParts.map((part) => {
      return setTimeout(
        () => checkImageStatus(part.id),
        CHECK_INTERVALS[checkCounts[part.id] || 0]
      );
    });

    return () => timeouts.forEach(clearTimeout);
  }, [dispatch, storyId, parts, checkCounts, imageStatus]);

  return { checkCounts, imageCheckErrors };
};

export default useImageStatusCheck;
