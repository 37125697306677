import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { fetchStoryPartsAudioStatus } from '../store/slices/storyPartSlice';
import { CHECK_INTERVALS } from '../constants/audio';

const useAudioStatusCheck = (
  storyId: string | undefined,
  parts: any[]
) => {
  const dispatch = useAppDispatch();
  const [checkCounts, setCheckCounts] = useState<Record<string, number>>({});
  const [audioCheckErrors, setAudioCheckErrors] = useState<Record<string, string | null>>({});
  const audioStatus = useAppSelector((state) => state.storyPart.audioStatus);

  useEffect(() => {
    if (!storyId || parts.length === 0) return;

    const checkAudioStatus = (partId: string) => {
      dispatch(fetchStoryPartsAudioStatus({ storyId, parts: [{ id: partId }] }))
        .unwrap()
        .then(() => {
          setCheckCounts((prev) => ({ ...prev, [partId]: (prev[partId] || 0) + 1 }));
        })
        .catch((error) => {
          console.error('Error fetching audio status:', error);
          setAudioCheckErrors((prev) => ({
            ...prev,
            [partId]: 'Unable to fetch audio status. Please try again later.'
          }));
        });
    };

    const unfinishedParts = parts.filter(
      (part) => !audioStatus[part.id]?.isGenerated && (checkCounts[part.id] || 0) < CHECK_INTERVALS.length
    );

    if (unfinishedParts.length === 0) return;

    const timeouts = unfinishedParts.map((part) => {
      return setTimeout(
        () => checkAudioStatus(part.id),
        CHECK_INTERVALS[checkCounts[part.id] || 0]
      );
    });

    return () => timeouts.forEach(clearTimeout);
  }, [dispatch, storyId, parts, checkCounts, audioStatus]);

  return { checkCounts, audioCheckErrors };
};

export default useAudioStatusCheck;
