import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/reduxHooks';
import { completePayment } from '../../store/slices/paymentSlice';
import Spinner from '../common/Spinner';

const PaymentSuccess: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const completePaymentProcess = async () => {
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      if (sessionId) {
        try {
          await dispatch(completePayment(sessionId)).unwrap();
        } catch (err) {
          setError(
            'Failed to complete payment process. Please contact support.'
          );
        }
      } else {
        setError(
          'Invalid session ID. Please try again or contact support.'
        );
      }
      setIsLoading(false);
    };

    completePaymentProcess();
  }, [dispatch, location]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center max-w-md">
        {error ? (
          <>
            <h1 className="text-3xl font-bold text-red-600 mb-4">
              Payment Error
            </h1>
            <p className="text-gray-600 mb-6">{error}</p>
          </>
        ) : (
          <>
            <h1 className="text-3xl font-bold text-green-600 mb-4">
              Payment Successful!
            </h1>
            <p className="text-gray-700 mb-4">
              Thank you for upgrading your plan. Your account has been updated.
            </p>
            <p className="text-purple-600 font-semibold mb-6">
              Get ready to embark on an incredible storytelling journey!
            </p>
            <div className="bg-purple-100 p-4 rounded-lg mb-6">
              <h2 className="text-xl font-bold text-purple-800 mb-2">What's Next?</h2>
              <ul className="text-left text-purple-700 list-disc list-inside">
                <li>Explore new story themes and characters</li>
                <li>Create longer, more immersive tales</li>
                <li>Enjoy enhanced interactive elements</li>
                <li>Spark your imagination like never before</li>
              </ul>
            </div>
          </>
        )}
        <Link
          to="/dashboard"
          className="bg-purple-600 text-white font-bold py-3 px-6 rounded-full hover:bg-purple-700 transition duration-300 inline-block"
        >
          Start Your Adventure
        </Link>
      </div>
    </div>
  );
};

export default PaymentSuccess;
