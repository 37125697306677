import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../hooks/reduxHooks';
import { fetchUserStories } from '../../store/slices/storySlice';
import FloatingActionButton from '../common/FloatingActionButton';
import Pagination from '../common/Pagination';
import { formatDate } from '../../utils/dateUtils';
import { fetchUser } from '../../store/slices/userSlice';
import { resetError } from '../../store/slices/storySlice';
import Spinner from '../common/Spinner';
import { motion } from 'framer-motion';
import confetti from 'canvas-confetti';
import { FaBook, FaMagic, FaFeatherAlt } from 'react-icons/fa';

const StoryHub: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { stories, isLoading, error, totalStories } = useAppSelector((state) => state.story);
  const { id: userId } = useAppSelector((state) => state.user);
  const [currentPage, setCurrentPage] = useState(1);
  const storiesPerPage = 8;

  useEffect(() => {
    if (!userId) {
      dispatch(fetchUser())
        .then(() => {
          dispatch(fetchUserStories({ userId: userId, page: currentPage, limit: storiesPerPage }));
        });
    } else {
      dispatch(fetchUserStories({ userId, page: currentPage, limit: storiesPerPage }));
    }
  }, [dispatch, userId, currentPage]);

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const handleCreateNewStory = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
    navigate('/create-story');
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="text-center mt-8 text-red-500 text-2xl">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Oops! A mischievous spell got in the way. 🌪️ {error}
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="max-w-6xl mx-auto mt-4 md:mt-8 p-4 md:p-6 bg-gradient-to-br from-purple-100 to-pink-100 rounded-lg shadow-lg"
    >
      <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6 text-purple-600 text-center">
        <motion.span
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 10 }}
          className="inline-block"
        >
          Your Magical Story Collection 🌟
        </motion.span>
      </h2>

      {stories.length === 0 ? (
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center text-gray-600 mb-6 md:mb-8 bg-white p-6 md:p-8 rounded-lg shadow-inner"
        >
          <FaFeatherAlt className="text-5xl text-purple-500 mx-auto mb-4" />
          <p className="text-lg md:text-xl mb-6">
            Your story book is empty! Let's fill it with magical adventures!
          </p>
          <button
            onClick={handleCreateNewStory}
            className="bg-purple-600 text-white font-bold py-3 px-6 rounded-full hover:bg-purple-700 transition duration-300 transform hover:scale-105 flex items-center justify-center mx-auto"
          >
            <FaMagic className="mr-2" /> Create Your First Story
          </button>
        </motion.div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {stories.map((story, index) => (
              <motion.div
                key={story.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <Link
                  to={`/story/${story.id}`}
                  className="block bg-white p-4 rounded-lg shadow-md hover:shadow-xl transition duration-300 transform hover:scale-105 h-full flex flex-col"
                >
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-3xl">{getStoryEmoji(story.title)}</span>
                    <span className={`text-sm px-2 py-1 rounded-full ${story.isCompleted ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'}`}>
                      {story.isCompleted ? 'Completed ✅' : 'In Progress 🚧'}
                    </span>
                  </div>
                  <h3 className="text-lg font-semibold text-purple-700 mb-2 flex-grow">
                    {story.title}
                  </h3>
                  <p className="text-sm text-gray-600 mb-2">
                    Created: {formatDate(story.createdAt)}
                  </p>
                  <div className="flex items-center text-purple-500">
                    <FaBook className="mr-1" />
                    <span className="text-sm">
                      {story.numberOfParts} chapter{story.numberOfParts !== 1 ? 's' : ''}
                    </span>
                  </div>
                </Link>
              </motion.div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            totalItems={totalStories}
            itemsPerPage={storiesPerPage}
            onPageChange={handlePageChange}
          />
        </>
      )}

      <FloatingActionButton
        onClick={handleCreateNewStory}
        className="fixed bottom-4 right-4 md:bottom-8 md:right-8"
        showTooltip={true}
      />
    </motion.div>
  );
};

const getStoryEmoji = (title: string) => {
  const emojis = ['🧙‍♂️', '🐉', '🦄', '🧚', '🌈', '🌟', '🚀', '🏰', '🌳', '🌊'];
  const hash = title.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return emojis[hash % emojis.length];
};

export default StoryHub;