import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/reduxHooks';
import {
  fetchUser,
  fetchSubscriptionDetails,
} from '../../store/slices/userSlice';
import UserPreferences from './UserPreferences';
import Spinner from '../common/Spinner';
import UserStats from '../common/UserStats';
import UserInfoCard from '../common/UserInfoCard';
import StoryPreferencesGrid from './StoryPreferencesGrid';
import SubscriptionCard from './SubscriptionCard';

const UserProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    id: userId,
    name,
    email,
    profilePicture,
    storyPreferences,
    subscriptionPlan,
    subscriptionDetails,
    isLoading,
    error,
  } = useAppSelector((state) => state.user);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (userId) {
      Promise.all([
        dispatch(fetchUser()),
        dispatch(fetchSubscriptionDetails())
      ]);
    }
  }, [dispatch, userId]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="text-center mt-8 text-red-500 text-xl md:text-2xl">
        Oops! A mischievous spell got in the way. 🌪️ {error}
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-6xl mx-auto mt-8 p-6 bg-gradient-to-br from-purple-100 to-pink-100 rounded-lg shadow-lg"
    >
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div>
          <UserInfoCard
            name={name}
            email={email}
            profilePicture={profilePicture}
            onEditClick={() => setIsEditing(true)}
          />
          <SubscriptionCard
            currentPlan={subscriptionPlan}
            subscriptionDetails={subscriptionDetails}
            className="mt-8"
          />
        </div>

        <div className="md:col-span-2">
          <UserStats />

          {/* Story Preferences Section */}
          <div className="bg-white p-6 rounded-lg shadow-md mt-8">
            <h3 className="text-xl font-semibold text-purple-700 mb-4 flex items-center">
              <span className="mr-2">✨</span> Your Magical Story
              Preferences
            </h3>
            {isEditing ? (
              <UserPreferences onClose={() => setIsEditing(false)} />
            ) : (
              <StoryPreferencesGrid
                storyPreferences={storyPreferences}
              />
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default UserProfile;
