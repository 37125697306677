import React from 'react';
import { FaLightbulb } from 'react-icons/fa';

interface DefinitionProps {
  content: string;
}

const Definition: React.FC<DefinitionProps> = ({ content }) => (
  <div>
    <div className="flex items-center mb-2">
      <FaLightbulb className="text-yellow-500 mr-2" />
      <h4 className="text-lg font-semibold">Definition</h4>
    </div>
    <p className="text-gray-700">{content}</p>
  </div>
);

export default Definition;