import React from 'react';
import { motion } from 'framer-motion';
import { InteractiveElement } from '../../types/interactiveElement';
import Definition from './interactive-elements/Definition';
import Fact from './interactive-elements/Fact';
import Image from './interactive-elements/Image';
import Animation from './interactive-elements/Animation';
import MiniGame from './interactive-elements/MiniGame';

interface InteractiveElementPopupProps {
  element: InteractiveElement;
  onClose: () => void;
}

const InteractiveElementPopup: React.FC<InteractiveElementPopupProps> = ({ element, onClose }) => {
  const renderContent = () => {
    switch (element.type) {
      case 'definition':
        return <Definition content={element.content} />;
      case 'fact':
        return <Fact content={element.content} />;
      case 'image':
        return <Image content={element.content} />;
      case 'animation':
        return <Animation content={element.content} />;
      case 'mini-game':
        return <MiniGame content={element.content} />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.9 }}
      className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50"
      onClick={onClose}
    >
      <motion.div
        className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full"
        onClick={(e) => e.stopPropagation()}
      >
        {renderContent()}
        <button
          onClick={onClose}
          className="mt-4 bg-purple-500 text-white px-4 py-2 rounded-full hover:bg-purple-600 transition duration-300"
        >
          Close
        </button>
      </motion.div>
    </motion.div>
  );
};

export default InteractiveElementPopup;