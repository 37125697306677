import AuthForm from './AuthForm';

function SignIn() {
  return (
    <div className="min-h-screen">
      <AuthForm mode="signup" />
    </div>
  );
}

export default SignIn;