import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SignIn from './components/auth/SignUp';
import StoryGenerator from './components/story/StoryGenerator';
import UserPreferences from './components/user/UserPreferences';
import Layout from './components/common/Layout';
import HomePage from './components/home/HomePage';
import ProtectedRoute from './components/common/ProtectedRoute';
import {
  AuthProvider,
  useAuth,
} from './components/auth/AuthProvider';
import UserProfile from './components/user/UserProfile';
import StoryHub from './components/story/StoryHub';
import ViewStory from './components/story/ViewStory';
import AdminLogin from './components/admin/AdminLogin';
import Dashboard from './components/dashboard/Dashboard';
import UpgradePlan from './components/payments/UpgradePlan';
import PaymentSuccess from './components/payments/PaymentSuccess';
import PaymentCancelled from './components/payments/PaymentCancelled';

const AppRoutes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <HomePage />
          )
        }
      />
      <Route path="signin" element={<SignIn />} />
      <Route path="admin/login" element={<AdminLogin />} />
      <Route element={<ProtectedRoute />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="preferences" element={<UserPreferences />} />
        <Route path="create-story" element={<StoryGenerator />} />
        <Route path="stories" element={<StoryHub />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="story/:id" element={<ViewStory />} />
        <Route path="upgrade" element={<UpgradePlan />} />
        <Route path="payment-success" element={<PaymentSuccess />} />
        <Route
          path="payment-cancelled"
          element={<PaymentCancelled />}
        />
      </Route>
    </Routes>
  );
};

function App() {
  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}
    >
      <Provider store={store}>
        <Router>
          <AuthProvider>
            <Layout>
              <AppRoutes />
            </Layout>
          </AuthProvider>
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
