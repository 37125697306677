import React from 'react';
import { motion } from 'framer-motion';
import { FaVolumeUp } from 'react-icons/fa';
import { CHECK_INTERVALS } from '../../constants/audio';

interface AudioSectionProps {
  audioStatus: { isGenerated: boolean; audioUrl: string };
  audioCheckError: string | null;
  checkCount: number;
}

const AudioSection: React.FC<AudioSectionProps> = ({ audioStatus, audioCheckError, checkCount }) => {
  if (audioStatus && audioStatus.isGenerated) {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="mt-4"
      >
        <h5 className="text-lg font-semibold mb-2 text-purple-600 flex items-center">
          <FaVolumeUp className="mr-2" />
          Listen to the magic! 🎧
        </h5>
        <audio controls src={audioStatus.audioUrl} className="w-full" />
      </motion.div>
    );
  }

  if (audioCheckError) {
    return <p className="mt-4 text-red-500">{audioCheckError}</p>;
  }

  if (audioStatus && !audioStatus.isGenerated) {
    return (
      <p className="mt-4 text-purple-800 animate-pulse">
        {checkCount < CHECK_INTERVALS.length
          ? 'Our magical creatures are working on the audio... 🧚‍♂️🔊'
          : 'Audio might take a bit longer to appear. Please check back later! 🌟'}
      </p>
    );
  }

  return null;
};

export default AudioSection;