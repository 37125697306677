import React from 'react';
import { FaGamepad } from 'react-icons/fa';

interface MiniGameProps {
  content: string;
}

const MiniGame: React.FC<MiniGameProps> = ({ content }) => (
  <div>
    <div className="flex items-center mb-2">
      <FaGamepad className="text-red-500 mr-2" />
      <h4 className="text-lg font-semibold">Mini-Game</h4>
    </div>
    <div className="w-full aspect-video bg-gray-200 rounded-lg">
      {/* Placeholder for mini-game content */}
      <p className="text-center py-8">Mini-game: {content}</p>
    </div>
  </div>
);

export default MiniGame;