import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import {
  fetchStoryWithParts,
  generateStory,
  continueStory,
} from './storySlice';
import { InteractiveElement } from '../../types/interactiveElement';

export type AudioStatus = {
  [key: string]: {
    isGenerated: boolean;
    audioUrl: string | undefined;
  };
};

export type ImageStatus = {
  [key: string]: {
    isGenerated: boolean;
    imageUrl: string | undefined;
  };
};

interface StoryPart {
  id: string;
  content: string;
  interactiveElements: InteractiveElement[];
}

interface StoryPartState {
  parts: StoryPart[];
  audioStatus: AudioStatus;
  imageStatus: ImageStatus;
  isLoading: boolean;
  error: string | null;
}

const initialState: StoryPartState = {
  parts: [],
  audioStatus: {},
  imageStatus: {},
  isLoading: false,
  error: null,
};

export const fetchStoryPartsAudioStatus = createAsyncThunk(
  'storyPart/fetchAudioStatus',
  async (
    { storyId, parts }: { storyId: string; parts: any[] },
    { rejectWithValue }
  ) => {
    const audioStatus: AudioStatus = {};

    for (const part of parts) {
      try {
        const response = await axiosInstance.get(
          `/stories/${storyId}/story-parts/${part.id}/audio-status`
        );
        audioStatus[part.id] = response.data.data;
      } catch (error) {
        console.error('Error fetching audio status:', error);
        return rejectWithValue(
          'An error occurred while fetching the audio status'
        );
      }
    }

    return audioStatus;
  }
);

export const fetchStoryPartsImageStatus = createAsyncThunk(
  'storyPart/fetchImageStatus',
  async (
    { storyId, parts }: { storyId: string; parts: any[] },
    { rejectWithValue }
  ) => {
    const imageStatus: ImageStatus = {};

    for (const part of parts) {
      try {
        const response = await axiosInstance.get(
          `/stories/${storyId}/story-parts/${part.id}/image-status`
        );
        imageStatus[part.id] = response.data.data;
      } catch (error) {
        console.error('Error fetching image status:', error);
        return rejectWithValue(
          'An error occurred while fetching the image status'
        );
      }
    }

    return imageStatus;
  }
);

const storyPartSlice = createSlice({
  name: 'storyPart',
  initialState,
  reducers: {
    resetStoryParts: (state) => {
      state.parts = [];
      state.audioStatus = {};
      state.imageStatus = {};
    },
    setStoryParts: (state, action: PayloadAction<StoryPart[]>) => {
      state.parts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoryPartsAudioStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchStoryPartsAudioStatus.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.audioStatus = {
            ...state.audioStatus,
            ...action.payload,
          };
        }
      )
      .addCase(
        fetchStoryPartsAudioStatus.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload as string;
        }
      )
      .addCase(fetchStoryPartsImageStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchStoryPartsImageStatus.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.imageStatus = {
            ...state.imageStatus,
            ...action.payload,
          };
        }
      )
      .addCase(
        fetchStoryPartsImageStatus.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload as string;
        }
      )
      .addCase(fetchStoryWithParts.fulfilled, (state, action) => {
        state.parts = action.payload.parts;
        // state.parts = action.payload.parts.map((part: StoryPart) => ({
        //   ...part,
        //   interactiveElements: part.interactiveElements || [],
        // }));
        state.isLoading = false;
        state.error = null;
      })
      .addCase(generateStory.fulfilled, (state, action) => {
        state.parts = [
          {
            id: action.payload.storyPartId,
            content: action.payload.content,
            interactiveElements:
              action.payload.interactiveElements || [],
          },
        ];
        state.isLoading = false;
        state.error = null;
      })
      .addCase(continueStory.fulfilled, (state, action) => {
        state.parts.push({
          id: action.payload.storyPartId,
          content: action.payload.content,
          interactiveElements:
            action.payload.interactiveElements || [],
        });
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { resetStoryParts, setStoryParts } =
  storyPartSlice.actions;
export default storyPartSlice.reducer;
