import React from 'react';

const Testimonials: React.FC = () => (
  <section className="bg-white py-8 sm:py-16">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-xl text-center">
        <h2 className="text-lg font-semibold leading-8 tracking-tight text-purple-600">Testimonials</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Loved by Parents and Children Alike
        </p>
      </div>
      <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:max-w-none">
        <div className="grid gap-x-8 gap-y-10 sm:grid-cols-2 lg:grid-cols-3">
          {[
            {
              name: 'Emily R.',
              role: 'Parent of two',
              imageUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
              quote: "StoryBuddyAI has transformed our bedtime routine. My kids can't wait to hear what adventure awaits them each night!",
            },
            {
              name: 'Michael T.',
              role: 'Elementary School Teacher',
              imageUrl: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
              quote: "I've seen a significant improvement in my students' reading comprehension and creativity since introducing StoryBuddyAI in the classroom.",
            },
            {
              name: 'Sarah L.',
              role: 'Child Psychologist',
              imageUrl: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
              quote: "The personalized stories created by StoryBuddyAI are an excellent tool for addressing children's emotional needs and fostering empathy.",
            },
          ].map((testimonial) => (
            <div key={testimonial.name} className="flex flex-col justify-between">
              <figure className="rounded-2xl bg-gray-50 p-8 text-sm leading-6 h-full">
                <blockquote className="text-gray-900">
                  <p>{`"${testimonial.quote}"`}</p>
                </blockquote>
                <figcaption className="mt-6 flex items-center gap-x-4">
                  <img className="h-10 w-10 rounded-full bg-gray-50" src={testimonial.imageUrl} alt="" />
                  <div>
                    <div className="font-semibold text-gray-900">{testimonial.name}</div>
                    <div className="text-gray-600">{`${testimonial.role}`}</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default Testimonials;
