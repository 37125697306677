import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';

interface NarrationVoice {
  id: string;
  accent: string;
  serviceVoiceId: string;
  gender: string;
}

interface NarrationVoiceState {
  voices: NarrationVoice[];
  isLoading: boolean;
  error: string | null;
}

const initialState: NarrationVoiceState = {
  voices: [],
  isLoading: false,
  error: null,
};

export const fetchNarrationVoices = createAsyncThunk(
  'narrationVoice/fetchVoices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/voices');
      return response.data.data;
    } catch (error) {
      console.error('Error fetching narration voices:', error);
      return rejectWithValue('Failed to fetch narration voices');
    }
  }
);

const narrationVoiceSlice = createSlice({
  name: 'narrationVoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNarrationVoices.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchNarrationVoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.voices = action.payload;
      })
      .addCase(fetchNarrationVoices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export default narrationVoiceSlice.reducer;
