import React from 'react';
import { FaImage } from 'react-icons/fa';

interface ImageProps {
  content: string;
}

const Image: React.FC<ImageProps> = ({ content }) => (
  <div>
    <div className="flex items-center mb-2">
      <FaImage className="text-green-500 mr-2" />
      <h4 className="text-lg font-semibold">Interactive Image</h4>
    </div>
    <img src={content} alt="Interactive content" className="w-full rounded-lg" />
  </div>
);

export default Image;