import React from 'react';
import { FaMagic } from 'react-icons/fa';

interface FactProps {
  content: string;
}

const Fact: React.FC<FactProps> = ({ content }) => (
  <div>
    <div className="flex items-center mb-2">
      <FaMagic className="text-blue-500 mr-2" />
      <h4 className="text-lg font-semibold">Fun Fact</h4>
    </div>
    <p className="text-gray-700">{content}</p>
  </div>
);

export default Fact;