import {
  createSlice,
  createAsyncThunk,
  createAction,
} from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import Cookies from 'js-cookie';
import { setUser } from './userSlice';

interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  error: string | null;
  isLoading: boolean;
}

const initialState: AuthState = {
  isAuthenticated: !!Cookies.get('accessToken'),
  accessToken: Cookies.get('accessToken') || null,
  error: null,
  isLoading: false,
};

export const googleAuth = createAsyncThunk(
  'auth/googleAuth',
  async (
    {
      credential,
      mode,
    }: { credential: string; mode: 'signup' | 'signin' },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`/auth/${mode}`, {
        credential,
      });

      // Dispatch the setUser action to update the user state
      dispatch(setUser(response.data.data.user));

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        `Error during Google ${mode}: ${error.message}`
      );
    }
  }
);

// export const logout = createAsyncThunk('auth/logout', async (_, { dispatch }) => {
//   Cookies.remove('accessToken');
//   Cookies.remove('refreshToken');
//   dispatch({ type: 'auth/logout' });
// });

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      console.log('action.payload', action.payload);
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      Cookies.set('accessToken', action.payload.accessToken, {
        expires: 7,
      });
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.accessToken = null;
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(googleAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(googleAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.accessToken = action.payload.accessToken;
        Cookies.set('accessToken', action.payload.accessToken, {
          expires: 7,
        });
      })
      .addCase(googleAuth.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(resetError, (state) => {
        state.error = null;
      });
  },
});

export const { login, logout } = authSlice.actions;
export const resetError = createAction('auth/resetError');
export default authSlice.reducer;
