import React from 'react';
import PreferenceItem from './PreferenceItem';
import { UserStoryPreferencesType } from '../../types/user';

interface StoryPreferencesGridProps {
  storyPreferences: UserStoryPreferencesType | null;
}

const StoryPreferencesGrid: React.FC<StoryPreferencesGridProps> = ({ storyPreferences }) => {
  const getAgeGroupDisplay = (ageGroup: string) => {
    switch (ageGroup) {
      case 'toddlers':
        return 'Toddlers (1-2 years)';
      case 'preschoolers':
        return 'Preschoolers (3-5 years)';
      case 'early readers':
        return 'Early Readers (6-8 years)';
      case 'older children':
        return 'Older Children (9-12 years)';
      default:
        return 'Not specified';
    }
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
      <PreferenceItem
        icon="📚"
        title="Favorite Genres"
        value={storyPreferences?.preferredGenres || ''}
        defaultMessage="You haven't picked any favorite genres yet. Time to explore!"
      />
      <PreferenceItem
        icon="📏"
        title="Story Length"
        value={storyPreferences?.storyLength || ''}
        defaultMessage="You haven't chosen a story length. Short, medium, or long?"
      />
      <PreferenceItem
        icon="😊"
        title="Story Tone"
        value={storyPreferences?.tonePreference || ''}
        defaultMessage="You haven't set a tone preference. Funny, serious, or something else?"
      />
      <PreferenceItem
        icon="👶"
        title="Age Group"
        value={storyPreferences?.ageGroup ? getAgeGroupDisplay(storyPreferences.ageGroup) : null}
        defaultMessage="You haven't selected an age group. What's your reading level?"
      />
      <PreferenceItem
        icon="🌈"
        title="Favorite Themes"
        value={storyPreferences?.favouriteThemes || ''}
        defaultMessage="You haven't chosen any favorite themes yet. What excites your imagination?"
      />
      <PreferenceItem
        icon="🎙️"
        title="Narration Voice"
        value={`${storyPreferences?.narrationVoiceId?.accent} - ${storyPreferences?.narrationVoiceId?.gender}`}
        defaultMessage="You haven't selected a narration voice yet. Choose your favorite accent!"
      />
    </div>
  );
};

export default StoryPreferencesGrid;