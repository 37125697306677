import React from 'react';
import { useAppSelector } from '../../hooks/reduxHooks';
import Hero from './Hero';
import Features from './Features';
import HowItWorks from './HowItWorks';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import FAQ from './FAQ';

const HomePage: React.FC = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-white">
      <main>
        <Hero isAuthenticated={isAuthenticated} />
        <Features />
        <HowItWorks />
        <Pricing isAuthenticated={isAuthenticated} />
        <Testimonials />
        <FAQ />
      </main>
    </div>
  );
};

export default HomePage;
