import React from 'react';
import { Link } from 'react-router-dom';
import { FaCrown, FaCog } from 'react-icons/fa';

interface SubscriptionCardProps {
  currentPlan: string;
  subscriptionDetails: {
    url: string;
    status: string;
    current_period_end: string;
  };
  className?: string;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  currentPlan,
  subscriptionDetails,
  className = '',
}) => {
  return (
    <div className={`bg-white p-6 rounded-lg shadow-md ${className}`}>
      <h3 className="text-xl font-semibold text-purple-700 mb-4 flex items-center">
        <FaCrown className="mr-2" /> Your Subscription
      </h3>
      <p className="text-lg mb-4">
        Current Plan:{' '}
        <span className="font-bold text-purple-600">
          {/* //@TODO: move to a function */}
          {currentPlan.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
        </span>
      </p>
      {subscriptionDetails && (
        <div className="mb-4">
          <p>Status: {subscriptionDetails.status}</p>
          <p>
            Next billing date:{' '}
            {new Date(
              subscriptionDetails.current_period_end
            ).toLocaleDateString()}
          </p>
        </div>
      )}
      <a
        href={subscriptionDetails?.url}
        className="block w-full text-center bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300 mb-2"
      >
        <FaCog className="inline-block mr-2" /> Manage Subscription
      </a>
      <Link
        to="/upgrade"
        className="block w-full text-center bg-green-600 text-white font-bold py-2 px-4 rounded-full hover:bg-green-700 transition duration-300"
      >
        Upgrade Plan
      </Link>
    </div>
  );
};

export default SubscriptionCard;
