import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { InteractiveElement } from '../../types/interactiveElement';

interface InteractiveElementState {
  elements: InteractiveElement[];
  isLoading: boolean;
  error: string | null;
}

const initialState: InteractiveElementState = {
  elements: [],
  isLoading: false,
  error: null,
};

export const fetchStoryInteractiveElements = createAsyncThunk(
  'interactiveElement/fetchStoryElements',
  async (storyId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/interactive-elements/story/${storyId}`
      );
      return response.data.data;
    } catch (error: any) {
      console.error('Error fetching interactive elements:', error);
      return rejectWithValue(
        error.response?.data?.message ||
          'Failed to fetch interactive elements'
      );
    }
  }
);

export const fetchStoryPartInteractiveElements = createAsyncThunk(
  'interactiveElement/fetchElements',
  async (storyPartId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/interactive-elements/story-part/${storyPartId}`
      );
      return response.data.data;
    } catch (error: any) {
      console.error('Error fetching interactive elements:', error);
      return rejectWithValue(
        error.response?.data?.message ||
          'Failed to fetch interactive elements'
      );
    }
  }
);

const interactiveElementSlice = createSlice({
  name: 'interactiveElement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStoryPartInteractiveElements.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchStoryPartInteractiveElements.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.elements = action.payload;
        }
      )
      .addCase(
        fetchStoryPartInteractiveElements.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload as string;
        }
      )
      .addCase(fetchStoryInteractiveElements.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchStoryInteractiveElements.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.elements = action.payload;
        }
      )
      .addCase(
        fetchStoryInteractiveElements.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload as string;
        }
      );
  },
});

export default interactiveElementSlice.reducer;
