import axiosInstance from './axiosInstance';

const refreshToken = async () => {
  try {
    const response = await axiosInstance.post(
      '/auth/refresh-token',
      {}
    );

    if (response.data && response.data.data.accessToken) {
      return response.data.data.accessToken;
    }

    throw new Error('No access token received');
  } catch (error) {
    throw error;
  }
};

export default refreshToken;
