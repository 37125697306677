import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { login, logout } from '../../store/slices/authSlice';
import { fetchUser } from '../../store/slices/userSlice';
import refreshToken from '../../utils/refreshToken';
import Cookies from 'js-cookie';
import { AppDispatch } from '../../store';
import { useLocation } from 'react-router-dom';
import Spinner from '../common/Spinner';

interface AuthContextType {
  isAuthenticated: boolean;
  accessToken: string | null;
  isLoading: boolean;
  login: (accessToken: string) => void;
  logout: () => void;
  refreshAccessToken: () => Promise<any>;
  setIsAuthenticated: (value: boolean) => void;
}

export const AuthContext = createContext<AuthContextType | null>(
  null
);

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated, accessToken } = useSelector(
    (state: RootState) => state.auth
  );
  const { id: userId } = useSelector(
    (state: RootState) => state.user
  );
  const location = useLocation();

  const [hasFetchedUser, setHasFetchedUser] = useState(false);

  useEffect(() => {
    const initializeAuth = async () => {
      if (accessToken && !isAuthenticated) {
        console.log('accessToken in AuthProvider', accessToken);
        dispatch(login({ accessToken }));
      }

      if (
        !userId &&
        // !hasFetchedUser &&
        isAuthenticated &&
        !['/signin', '/signup'].includes(location.pathname)
      ) {
        // const isPreferencesPage =
        //   location.pathname === '/preferences';
        await dispatch(fetchUser());
        setHasFetchedUser(true);
      }

      setIsLoading(false);
    };

    initializeAuth();
  }, [
    dispatch,
    isAuthenticated,
    accessToken,
    userId,
    hasFetchedUser,
    location.pathname,
  ]);

  if (isLoading) {
    return <Spinner />;
  }

  const loginHandler = (accessToken: string) => {
    dispatch(login({ accessToken }));
  };

  const logoutHandler = () => {
    dispatch(logout());
    setHasFetchedUser(false);
  };

  const refreshAccessToken = async () => {
    try {
      const newAccessToken = await refreshToken();
      dispatch(login({ accessToken: newAccessToken }));
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token:', error);
      logoutHandler();
      throw error;
    }
  };

  const setIsAuthenticated = (value: boolean) => {
    if (value) {
      dispatch(
        login({ accessToken: Cookies.get('accessToken') || '' })
      );
    } else {
      dispatch(logout());
    }
  };

  const value = {
    isAuthenticated,
    accessToken,
    isLoading,
    login: loginHandler,
    logout: logoutHandler,
    refreshAccessToken,
    setIsAuthenticated,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
