import React from 'react';
import { motion } from 'framer-motion';
import {
  StoryContentProps,
  EnrichedStoryPart,
} from '../../types/story';
import StoryPart from './StoryPart';

const StoryContent: React.FC<StoryContentProps> = ({
  storyParts,
  title,
  audioStatus,
  imageStatus,
  audioCheckErrors,
  imageCheckErrors,
  checkCounts,
  interactiveElements,
}) => {
  const enrichedStoryParts: EnrichedStoryPart[] = storyParts.map(
    (part) => ({
      ...part,
      audioStatus: audioStatus[part.id],
      imageStatus: imageStatus[part.id],
      audioCheckError: audioCheckErrors[part.id],
      imageCheckError: imageCheckErrors[part.id],
      checkCount: checkCounts[part.id],
      // @TODO: can FIND be used here
      interactiveElements: interactiveElements.filter(
        (element) => element.storyPartId === part.id
      ),
    })
  );

  return (
    <div className="story-content">
      {title && (
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-purple-700 text-center"
        >
          {title}
        </motion.h2>
      )}
      {enrichedStoryParts.map((part, index) => (
        <StoryPart key={part.id} part={part} index={index} />
      ))}
    </div>
  );
};

export default StoryContent;
