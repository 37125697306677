import React from 'react';

interface PreferenceItemProps {
  icon: string;
  title: string;
  value: string | string[] | null;
  defaultMessage: string;
}

const PreferenceItem: React.FC<PreferenceItemProps> = ({
  icon,
  title,
  value,
  defaultMessage,
}) => {
  const displayValue = Array.isArray(value)
    ? value.join(', ')
    : value;

  return (
    <div className="flex items-start">
      <span className="text-3xl mr-4">{icon}</span>
      <div>
        <h4 className="text-lg font-semibold text-purple-600 mb-1">
          {title}
        </h4>
        <p className="text-gray-700">
          {displayValue ? (
            displayValue
          ) : (
            <span className="italic text-purple-400">
              {defaultMessage}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default PreferenceItem;