import React from 'react';
import { Link } from 'react-router-dom';

const PaymentCancelled: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md text-center max-w-md">
        <h1 className="text-3xl font-bold text-red-600 mb-4">
          Payment Cancelled
        </h1>
        <p className="text-gray-700 mb-4">
          Your payment was cancelled. No worries, it happens sometimes!
        </p>
        <p className="text-purple-600 font-semibold mb-6">
          You can still enjoy our amazing storytelling features!
        </p>
        <div className="bg-purple-100 p-4 rounded-lg mb-6">
          <h2 className="text-xl font-bold text-purple-800 mb-2">Did You Know?</h2>
          <ul className="text-left text-purple-700 list-disc list-inside">
            <li>You can create wonderful stories with our free plan</li>
            <li>Upgrade anytime to unlock more features</li>
            <li>Our support team is here to help with any questions</li>
            <li>Every story you create is a new adventure</li>
          </ul>
        </div>
        <div className="space-y-4">
          <Link
            to="/upgrade"
            className="bg-purple-600 text-white font-bold py-3 px-6 rounded-full hover:bg-purple-700 transition duration-300 inline-block"
          >
            Try Upgrading Again
          </Link>
          <p className="text-gray-600">or</p>
          <Link
            to="/dashboard"
            className="text-purple-600 font-semibold hover:text-purple-800 transition duration-300"
          >
            Return to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancelled;
