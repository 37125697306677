import React from 'react';
import { motion } from 'framer-motion';

const Spinner: React.FC = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{
          duration: 1,
          repeat: Infinity,
          ease: 'linear',
        }}
        className="w-16 h-16 border-t-4 border-purple-500 border-solid rounded-full"
      />
    </div>
  );
};

export default Spinner;