import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/reduxHooks';
import {
  fetchStoryWithParts,
  continueStory,
  updateStory,
} from '../../store/slices/storySlice';
import {
  fetchStoryPartsAudioStatus,
  fetchStoryPartsImageStatus,
} from '../../store/slices/storyPartSlice';
import { fetchStoryInteractiveElements } from '../../store/slices/interactiveElementSlice';
import { formatDate } from '../../utils/dateUtils';
import useAudioStatusCheck from '../../hooks/useAudioStatusCheck';
import useImageStatusCheck from '../../hooks/useImageStatusCheck';
import ContinuationForm from './ContinuationForm';
import confetti from 'canvas-confetti';
import StoryContent from './StoryContent';
import Spinner from '../common/Spinner';
// import ProgressBar from '../common/ProgressBar';
import { motion } from 'framer-motion';

const FloatingElement: React.FC<{
  emoji: string;
  style: React.CSSProperties;
}> = ({ emoji, style }) => {
  return (
    <motion.div
      className="absolute text-4xl"
      style={style}
      initial={{ y: 0 }}
      animate={{
        y: [0, -10, 0],
        transition: {
          duration: 2,
          repeat: Infinity,
          repeatType: 'reverse',
        },
      }}
    >
      {emoji}
    </motion.div>
  );
};

const InteractiveElement: React.FC<{
  emoji: string;
  style: React.CSSProperties;
}> = ({ emoji, style }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="absolute text-4xl cursor-pointer"
      style={style}
      whileHover={{ scale: 1.2, rotate: 360 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {emoji}
      {isHovered && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute top-full left-1/2 transform -translate-x-1/2 bg-white p-2 rounded shadow-md text-sm"
        >
          Magic!
        </motion.div>
      )}
    </motion.div>
  );
};

const ViewStory: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const {
    id: storyId,
    title,
    summary,
    createdAt,
    isLoading: storyLoading,
    error: storyError,
    isCompleted,
  } = useAppSelector((state) => state.story);
  const {
    parts,
    audioStatus,
    imageStatus,
    error: partsError,
  } = useAppSelector((state) => state.storyPart);
  const {
    elements: interactiveElements,
    error: interactiveElementsError,
  } = useAppSelector((state) => state.interactiveElement);

  const [showCompletionModal, setShowCompletionModal] =
    useState(false);

  const { checkCounts: audioCheckCounts, audioCheckErrors } =
    useAudioStatusCheck(id, parts);

  const { checkCounts: imageCheckCounts, imageCheckErrors } =
    useImageStatusCheck(id, parts);

  useEffect(() => {
    if (id) {
      dispatch(fetchStoryWithParts(id)).then((result) => {
        if (fetchStoryWithParts.fulfilled.match(result)) {
          // After successfully fetching the story with parts, fetch interactive elements
          dispatch(fetchStoryInteractiveElements(id));
        }
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (parts && parts.length > 0) {
      //@TODO: check if the image & audio status are already returned from the story, if not fetch them
      dispatch(fetchStoryPartsImageStatus({ storyId, parts }));
      dispatch(fetchStoryPartsAudioStatus({ storyId, parts }));
    }
  }, [dispatch, storyId, parts]);

  const handleContinueStory = (continuationPrompt: string) => {
    dispatch(
      continueStory({
        storyId: storyId,
        continuationPrompt,
      })
    );
  };

  const handleCompleteStory = () => {
    if (storyId) {
      dispatch(updateStory({ storyId, isCompleted: true }));
      setShowCompletionModal(true);
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  };

  // const totalParts = 5; // You might want to fetch this from the story metadata or make it dynamic
  // const currentPart = parts.length;

  if (storyLoading) {
    return <Spinner />;
  }

  if (storyError || partsError || interactiveElementsError) {
    return (
      <div className="text-center mt-8 text-red-500 text-2xl">
        Oops! The story got lost in a magical forest. 🌳{' '}
        {storyError || partsError || interactiveElementsError}
      </div>
    );
  }

  if (!storyId) {
    return (
      <div className="text-center mt-8 text-2xl text-purple-600">
        Oh no! This story seems to have vanished into thin air! 🌬️
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="max-w-4xl mx-auto mt-4 md:mt-8 p-4 md:p-6 bg-gradient-to-br from-purple-100 to-pink-100 rounded-lg shadow-lg relative"
    >
      <FloatingElement emoji="🌟" style={{ top: '10px', left: '10px' }} />
      <FloatingElement emoji="🦄" style={{ top: '10px', right: '10px' }} />
      <FloatingElement emoji="🌈" style={{ bottom: '10px', left: '10px' }} />
      <FloatingElement emoji="🧚" style={{ bottom: '10px', right: '10px' }} />

      <InteractiveElement emoji="🔮" style={{ top: '50px', left: '50px' }} />
      <InteractiveElement emoji="📚" style={{ top: '50px', right: '50px' }} />
      <InteractiveElement emoji="🧙‍♂️" style={{ bottom: '50px', left: '50px' }} />
      <InteractiveElement emoji="🏰" style={{ bottom: '50px', right: '50px' }} />

      <h2 className="text-3xl md:text-4xl font-bold mb-2 md:mb-4 text-purple-600 text-center animate-pulse">
        {title}
      </h2>
      <p className="text-sm md:text-base text-gray-600 mb-4 text-center">
        Story created {formatDate(createdAt)} ✨
      </p>

      {/* <ProgressBar currentPart={currentPart} totalParts={totalParts} /> */}

      <div className="bg-white p-4 md:p-6 rounded-lg mb-4 md:mb-6 shadow-inner relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500"></div>
        <h3 className="text-xl md:text-2xl font-semibold mb-2 text-purple-700 flex items-center">
          <span className="mr-2 text-2xl md:text-3xl">📖</span> Story Summary
        </h3>
        <p className="text-sm md:text-base text-gray-800 italic">{summary}</p>
      </div>

      <StoryContent
        storyParts={parts}
        title={title}
        audioStatus={audioStatus}
        imageStatus={imageStatus}
        audioCheckErrors={audioCheckErrors}
        imageCheckErrors={imageCheckErrors}
        checkCounts={{ ...audioCheckCounts, ...imageCheckCounts }}
        interactiveElements={interactiveElements}
      />

      {!isCompleted && parts.length > 0 && (
        <div className="mt-6 md:mt-8 text-center">
          <button
            onClick={handleCompleteStory}
            className="px-4 md:px-6 py-2 md:py-3 bg-purple-600 text-white rounded-full text-lg md:text-xl font-bold shadow-lg hover:bg-purple-500 transition duration-300 transform hover:scale-105 animate-bounce"
          >
            🎉 Finish the Story 🎉
          </button>
        </div>
      )}

      {!isCompleted && (
        <ContinuationForm
          onSubmit={handleContinueStory}
          isLoading={storyLoading}
        />
      )}

      {showCompletionModal && (
        <StoryCompletionModal
          onClose={() => setShowCompletionModal(false)}
        />
      )}
    </motion.div>
  );
};

const StoryCompletionModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md text-center">
        <h3 className="text-3xl font-bold text-purple-600 mb-4">
          🎊 Hooray! 🎊
        </h3>
        <p className="text-xl text-gray-700 mb-6">
          You've completed your magical story! It's now a shining star
          in your collection of adventures!
        </p>
        <button
          onClick={onClose}
          className="px-6 py-3 bg-purple-600 text-white rounded-full text-lg font-bold shadow-lg hover:bg-purple-500 transition duration-300 transform hover:scale-105"
        >
          Close and Celebrate! 🥳
        </button>
      </div>
    </div>
  );
};

export default ViewStory;
