import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import axiosInstance from '../../utils/axiosInstance';
import { UserState } from '../../types/user';

const initialState: UserState = {
  id: '',
  name: null,
  email: null,
  profilePicture: null,
  withAudio: false,
  storyPreferences: {
    preferredGenres: [],
    storyLength: 'medium',
    tonePreference: 'humorous',
    ageGroup: 'preschoolers',
    favouriteThemes: [],
    narrationVoiceId: {
      id: '',
      accent: '',
      gender: 'male',
      language: '',
    },
    learningGoals: [],
  },
  storyCount: 0,
  wordsGenerated: 0,
  isStoryPreferencesSet: false,
  achievements: [],
  isLoading: false,
  error: null,
  subscriptionPlan: 'Tale Taster',
  subscriptionDetails: {
    url: '',
    status: '',
    current_period_end: '',
  },
};

export const fetchUserPreferences = createAsyncThunk(
  'user/fetchPreferences',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/users/preferences');
      return response.data;
    } catch (error) {
      return rejectWithValue('Failed to fetch user preferences');
    }
  }
);

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get('/users');
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response.data.message || 'Failed to fetch user data'
      );
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (
    {
      userId,
      userData,
    }: { userId: string; userData: Partial<UserState> },
    { rejectWithValue }
  ) => {
    const { storyPreferences } = userData;

    if (storyPreferences && storyPreferences.narrationVoiceId) {
      const narrationVoiceId = storyPreferences.narrationVoiceId.id;
      delete storyPreferences.narrationVoiceId;
      (storyPreferences.narrationVoiceId as any) = narrationVoiceId;
    }

    try {
      const response = await axiosInstance.patch(
        `/users/${userId}`,
        userData
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to update user data'
      );
    }
  }
);

export const fetchSubscriptionDetails = createAsyncThunk(
  'user/fetchSubscriptionDetails',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        '/payments/subscription-details'
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message ||
          'Failed to fetch subscription details'
      );
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null,
      };
    },
    clearUser: () => initialState,
    resetLoadingState: (state) => {
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.id = action.payload.id;
        state.name = action.payload.name;
        state.email = action.payload.email;
        state.profilePicture = action.payload.profilePicture;
        state.storyPreferences = action.payload.storyPreferences;
        state.storyCount = action.payload.storyCount;
        state.wordsGenerated = action.payload.wordsGenerated;
        state.achievements = action.payload.achievements;
        state.isStoryPreferencesSet =
          action.payload.isStoryPreferencesSet;
        state.subscriptionPlan = action.payload.subscriptionPlan;
        state.error = null;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.error.message || 'Failed to fetch user data';
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        Object.assign(state, action.payload);
        state.isStoryPreferencesSet =
          action.payload.isStoryPreferencesSet;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(resetError, (state) => {
        state.error = null;
      })
      .addCase(fetchSubscriptionDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(
        fetchSubscriptionDetails.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.subscriptionDetails = action.payload;
        }
      )
      .addCase(fetchSubscriptionDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setUser, clearUser, resetLoadingState } =
  userSlice.actions;
export const resetError = createAction('user/resetError');
export default userSlice.reducer;
