import React from 'react';
import { motion } from 'framer-motion';
import { CHECK_INTERVALS } from '../../constants/audio';

interface ImageSectionProps {
  imageStatus: { isGenerated: boolean; imageUrl: string };
  imageCheckError: string | null;
  checkCount: number;
}

const ImageSection: React.FC<ImageSectionProps> = ({ imageStatus, imageCheckError, checkCount }) => {
  if (imageStatus && imageStatus.isGenerated) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="mt-4 mb-4"
      >
        <img
          src={imageStatus.imageUrl}
          alt="Story illustration"
          className="w-full rounded-lg shadow-md"
        />
      </motion.div>
    );
  }

  if (imageCheckError) {
    return <p className="mt-4 text-red-500">{imageCheckError}</p>;
  }

  if (imageStatus && !imageStatus.isGenerated) {
    return (
      <p className="mt-4 text-purple-800 animate-pulse">
        {checkCount < CHECK_INTERVALS.length
          ? 'Our magical artists are creating an image... 🎨✨'
          : 'Image might take a bit longer to appear. Please check back later! 🖼️'}
      </p>
    );
  }

  return null;
};

export default ImageSection;