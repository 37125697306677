import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { EnrichedStoryPart } from '../../types/story';
import AudioSection from './AudioSection';
import ImageSection from './ImageSection';
import InteractiveElementPopup from './InteractiveElementPopup';
import { InteractiveElement } from '../../types/interactiveElement';

interface StoryPartProps {
  part: EnrichedStoryPart;
  index: number;
}

const StoryPart: React.FC<StoryPartProps> = ({ part, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeElement, setActiveElement] =
    useState<InteractiveElement | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const highlightInteractiveElements = () => {
      if (!contentRef.current) return;

      const content = part.content;
      const lines = content.split('\n');
      const highlightedLines = lines.map((line) => {
        let highlightedLine = line;
        part.interactiveElements.forEach((element) => {
          const regex = new RegExp(`\\b${element.trigger}\\b`, 'gi');
          highlightedLine = highlightedLine.replace(
            regex,
            (match) => {
              return `<span class="interactive-element" data-element-id="${element.id}">${match}</span>`;
            }
          );
        });
        return highlightedLine;
      });

      contentRef.current.innerHTML = highlightedLines.join('<br>');

      contentRef.current
        .querySelectorAll('.interactive-element')
        .forEach((el) => {
          el.addEventListener('click', handleInteractiveElementClick);
        });
    };

    const handleInteractiveElementClick = (e: Event) => {
      const target = e.target as HTMLElement;
      const elementId = target.getAttribute('data-element-id');
      if (elementId) {
        const element = part.interactiveElements.find(
          (el) => el.id === elementId
        );
        if (element) {
          setActiveElement(element);
        }
      }
    };

    highlightInteractiveElements();
  }, [part.content, part.interactiveElements]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      className="mb-6 bg-white p-4 rounded-lg shadow-md"
    >
      <h3 className="text-lg md:text-xl font-semibold mb-2 text-purple-600">
        Part {index + 1}
      </h3>
      <ImageSection
        imageStatus={part.imageStatus}
        imageCheckError={part.imageCheckError}
        checkCount={part.checkCount}
      />
      <div
        ref={contentRef}
        className={`text-gray-800 mb-4 whitespace-pre-wrap ${
          isExpanded ? '' : 'line-clamp-3'
        }`}
      ></div>
      <motion.button
        onClick={() => setIsExpanded(!isExpanded)}
        className="text-purple-600 font-semibold hover:text-purple-800 transition duration-300"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {isExpanded ? 'Read less' : 'Read more'}
      </motion.button>
      <AudioSection
        audioStatus={part.audioStatus}
        audioCheckError={part.audioCheckError}
        checkCount={part.checkCount}
      />
      <AnimatePresence>
        {activeElement && (
          <InteractiveElementPopup
            element={activeElement}
            onClose={() => setActiveElement(null)}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default StoryPart;
