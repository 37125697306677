import axios from 'axios';
import Cookies from 'js-cookie';
import refreshToken from './refreshToken';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

let isRefreshing = false;
let refreshPromise: Promise<string> | null = null;

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshPromise = refreshToken()
          .then((newAccessToken) => {
            isRefreshing = false;
            return newAccessToken;
          })
          .catch((refreshError) => {
            isRefreshing = false;
            Cookies.remove('accessToken');
            Cookies.remove('refreshToken');
            window.location.href = '/signin';
            return Promise.reject(refreshError);
          });
      }

      try {
        const newAccessToken = await refreshPromise;
        if (newAccessToken) {
          Cookies.set('accessToken', newAccessToken);
          originalRequest.headers[
            'Authorization'
          ] = `Bearer ${newAccessToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
