import React, { useState } from 'react';

const FAQ: React.FC = () => {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqs = [
    {
      question: "How does StoryBuddyAI create personalized stories?",
      answer: "StoryBuddyAI uses advanced AI algorithms to generate unique stories based on your child's interests, age, and learning goals. You can input preferences and prompts, and our AI will craft a tailored story that engages and educates your child.",
    },
    {
      question: "Is StoryBuddyAI suitable for all age groups?",
      answer: "Yes! StoryBuddyAI can create stories for children of all ages, from toddlers to young teens. The content and complexity of the stories are adjusted based on the age group you select when setting up your account or creating a new story.",
    },
    {
      question: "Can I save and revisit stories created with StoryBuddyAI?",
      answer: "Absolutely! All stories generated by StoryBuddyAI are saved to your account. You can revisit, continue, or share these stories at any time. This feature allows your child to build on their favorite adventures or rediscover past creations.",
    },
    {
      question: "What platforms does StoryBuddyAI use for story generation?",
      answer: "StoryBuddyAI utilizes a combination of advanced language models, including GPT-4o and Google's Gemini, to generate engaging and age-appropriate stories. These AI models are continuously fine-tuned to ensure the highest quality and most creative storytelling experience for your child.",
    },
    {
      question: "Which platform does StoryBuddyAI use for text-to-speech and voice generation?",
      answer: "For our text-to-speech feature, StoryBuddyAI uses OpenAI's Text-to-Speech (TTS) as our primary service and ElevenLabs as a backup. This allows us to provide high-quality, natural-sounding narration for our stories, enhancing the listening experience for children. It's important to note that the voices used in our stories are AI-generated, providing a consistent and customizable audio experience while maintaining the highest standards of quality and clarity.",
    },
    {
      question: "How are the images in StoryBuddyAI stories optimized for children?",
      answer: "StoryBuddyAI uses advanced image generation techniques to create visuals that are engaging, age-appropriate, and safe for children. Our AI ensures that images are colorful, friendly, and free from potentially disturbing content. We also optimize image quality and size for smooth loading across various devices, ensuring a seamless storytelling experience.",
    },
  ];

  return (
    <section className="bg-white py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-purple-600">Frequently Asked Questions</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Everything You Need to Know
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="space-y-4">
            {faqs.map((faq, index) => (
              <div key={faq.question} className="border-t border-gray-200 pt-6">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <button
                    type="button"
                    className="flex w-full items-start justify-between text-left"
                    onClick={() => toggleItem(index)}
                  >
                    <span className="text-base font-semibold leading-7 text-gray-900">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      {openItem === index ? (
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      ) : (
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                        </svg>
                      )}
                    </span>
                  </button>
                </dt>
                {openItem === index && (
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p>{faq.answer}</p>
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
