import React from 'react';
import { motion } from 'framer-motion';
import { AchievementType } from '../../types/achievement';

interface AchievementCardProps {
  achievement: AchievementType;
}

const AchievementCard: React.FC<AchievementCardProps> = ({ achievement }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-white rounded-lg shadow-md p-4 text-center"
    >
      <div className="text-4xl mb-2">{achievement.icon}</div>
      <h3 className="text-lg font-semibold text-purple-600 mb-1">{achievement.title}</h3>
      <p className="text-sm text-gray-600">{achievement.description}</p>
    </motion.div>
  );
};

export default AchievementCard;