import React from 'react';
import { Link } from 'react-router-dom';

interface HeroProps {
  isAuthenticated: boolean;
}

const Hero: React.FC<HeroProps> = ({ isAuthenticated }) => (
  <section className="relative overflow-hidden py-20 sm:py-32 lg:pb-16 xl:pb-36">
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 relative z-10">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 lg:gap-y-20">
        <div className="relative z-10 mx-auto max-w-2xl lg:col-span-7 lg:max-w-none lg:pt-6 xl:col-span-6">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
            Unleash Your Child's Imagination with AI-Powered Stories
          </h1>
          <p className="mt-6 text-lg text-gray-600">
            StoryBuddyAI creates personalized, interactive stories that spark creativity and foster a love for reading in children of all ages.
          </p>
          <div className="mt-8 flex flex-wrap gap-x-6 gap-y-4">
            {isAuthenticated ? (
              <Link
                to="/create-story"
                className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                Create Your Story
              </Link>
            ) : (
              <Link
                to="/signin"
                className="rounded-md bg-purple-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                Get Started
              </Link>
            )}
            <a
              href="#how-it-works"
              className="text-sm font-semibold leading-6 text-gray-900 flex items-center justify-center"
              onClick={(e) => {
                e.preventDefault();
                const howItWorksSection = document.getElementById('how-it-works');
                if (howItWorksSection) {
                  howItWorksSection.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
        <div className="relative mt-10 sm:mt-20 lg:col-span-5 lg:row-span-2 lg:mt-0 xl:col-span-6">
          <div className="absolute left-1/2 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-gray-300/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0">
            <svg
              viewBox="0 0 1026 1026"
              fill="none"
              aria-hidden="true"
              className="absolute inset-0 h-full w-full animate-spin-slow"
            >
              <path
                d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
                stroke="#D4D4D4"
                strokeOpacity="0.7"
              />
              <path
                d="M513 1025C230.23 1025 1 795.77 1 513"
                stroke="url(#:R65m:-gradient-1)"
                strokeLinecap="round"
              />
              <defs>
                <linearGradient
                  id=":R65m:-gradient-1"
                  x1="1"
                  y1="513"
                  x2="1"
                  y2="1025"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#A855F7" />
                  <stop offset="1" stopColor="#A855F7" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="relative">
            <img
              src="/storybuddy-demo.gif"
              alt="StoryBuddyAI in action"
              className="rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              width={2432}
              height={1442}
            />
            <div className="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
