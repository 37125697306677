import React from 'react';
import { motion } from 'framer-motion';
import { IconType } from 'react-icons';

interface StatCardProps {
  icon: IconType;
  title: string;
  value: number;
}

const StatCard: React.FC<StatCardProps> = ({ icon: Icon, title, value }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className="bg-white rounded-lg shadow-md p-6"
    >
      <div className="flex items-center mb-2">
        <Icon className="text-purple-600 text-2xl mr-2" />
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
      </div>
      <p className="text-3xl font-bold text-purple-600">{value}</p>
    </motion.div>
  );
};

export default StatCard;