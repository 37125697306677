import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import storyReducer from './slices/storySlice';
import storyPartReducer from './slices/storyPartSlice';
import achievementReducer from './slices/achievementSlice';
import narrationVoiceReducer from './slices/narrationVoiceSlice';
import interactiveElementReducer from './slices/interactiveElementSlice';
import paymentReducer from './slices/paymentSlice';
import planReducer from './slices/planSlice';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  plans: planReducer,
  story: storyReducer,
  payment: paymentReducer,
  storyPart: storyPartReducer,
  achievements: achievementReducer,
  narrationVoice: narrationVoiceReducer,
  interactiveElement: interactiveElementReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: any) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;