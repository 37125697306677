import React from 'react';
import { FaEdit } from 'react-icons/fa';

interface UserInfoCardProps {
  name: string | null;
  email: string | null;
  profilePicture: string | null;
  onEditClick: () => void;
}

const UserInfoCard: React.FC<UserInfoCardProps> = ({ name, email, profilePicture, onEditClick }) => (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <div className="flex flex-col items-center">
      <img
        src={profilePicture || '/default-avatar.jpg'}
        alt={name || 'User'}
        className="w-32 h-32 rounded-full mb-4 border-4 border-purple-300"
      />
      <h2 className="text-2xl font-bold text-purple-600 mb-2">{name}</h2>
      <p className="text-gray-600 mb-4">{email}</p>
      <button
        onClick={onEditClick}
        className="bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-700 transition duration-300 flex items-center"
      >
        <FaEdit className="mr-2" /> Edit Profile
      </button>
    </div>
  </div>
);

export default UserInfoCard;