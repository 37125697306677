import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { plans, Plan } from '../../store/planData';

interface PricingProps {
  isAuthenticated: boolean;
}

const Pricing: React.FC<PricingProps> = ({ isAuthenticated }) => {
  const [isYearly, setIsYearly] = useState(false);
  const navigate = useNavigate();

  const handlePlanSelection = (planName: string) => {
    if (isAuthenticated) {
      navigate('/upgrade');
    } else {
      navigate('/signin', { state: { from: '/upgrade' } });
    }
  };

  const filteredPlans = plans.filter(plan => plan.interval === (isYearly ? 'year' : 'month'));

  return (
    <section id="pricing" className="bg-gray-50 py-8 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="sm:text-3xl md:text-4xl font-bold tracking-tight text-gray-900">
            Choose Your Storytelling Adventure
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Select a plan that fits your family's storytelling needs
            and unlock a world of imagination.
          </p>
        </div>

        <div className="mt-8 flex justify-center">
          <div className="relative flex rounded-full bg-white p-1 shadow-sm">
            <button
              onClick={() => setIsYearly(false)}
              className={`${
                !isYearly
                  ? 'bg-purple-600 text-white'
                  : 'text-gray-500'
              } rounded-full py-2 px-4 text-sm font-semibold transition-all duration-200`}
            >
              Monthly
            </button>
            <button
              onClick={() => setIsYearly(true)}
              className={`${
                isYearly
                  ? 'bg-purple-600 text-white'
                  : 'text-gray-500'
              } rounded-full py-2 px-4 text-sm font-semibold transition-all duration-200`}
            >
              Yearly (Save 16%)
            </button>
          </div>
        </div>

        <div className="mx-auto mt-16 grid max-w-lg gap-8 lg:max-w-none lg:grid-cols-3">
          {filteredPlans.map((plan: Plan) => (
            <div
              key={plan.name}
              className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
            >
              <div>
                <h3 className="text-2xl font-bold tracking-tight text-purple-600">
                  {plan.name}
                </h3>
                <p className="mt-4 text-base text-gray-600">
                  {plan.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    ${plan.price.toFixed(2)}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /{plan.interval}
                  </span>
                </p>
                <ul className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {plan.features.map((feature: string) => (
                    <li key={feature} className="flex gap-x-3">
                      <svg
                        className="h-6 w-5 flex-none text-purple-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                          clipRule="evenodd"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <button
                onClick={() => handlePlanSelection(plan.name)}
                className="mt-8 block w-full rounded-md bg-purple-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                {isAuthenticated
                  ? `Upgrade to ${plan.name}`
                  : `Get Started with ${plan.name}`}
              </button>
            </div>
          ))}
        </div>

        <p className="mt-12 text-center text-sm text-gray-500">
          All plans come with a 14-day free trial. No credit card
          required.
        </p>
      </div>
    </section>
  );
};

export default Pricing;
