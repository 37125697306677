import React, { useState, useEffect } from 'react';
import {
  useAppSelector,
  useAppDispatch,
} from '../../hooks/reduxHooks';
import {
  generateStory,
  continueStory,
  resetStory,
} from '../../store/slices/storySlice';
import { resetStoryParts } from '../../store/slices/storyPartSlice';
import { resetError } from '../../store/slices/storySlice';
import ContinuationForm from './ContinuationForm';
import useAudioStatusCheck from '../../hooks/useAudioStatusCheck';
import StoryContent from './StoryContent';
import useImageStatusCheck from '../../hooks/useImageStatusCheck';
import confetti from 'canvas-confetti';
import {
  FaDragon,
  FaTree,
  FaGuitar,
  FaSeedling,
} from 'react-icons/fa';
import { fetchStoryPartInteractiveElements } from '../../store/slices/interactiveElementSlice';

function StoryGenerator() {
  const [prompt, setPrompt] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const dispatch = useAppDispatch();

  const { id, title, isLoading, error } = useAppSelector(
    (state) => state.story
  );
  const { parts, audioStatus, imageStatus } = useAppSelector(
    (state) => state.storyPart
  );
  const { checkCounts: audioCheckCounts, audioCheckErrors } =
    useAudioStatusCheck(id, parts);
  const { checkCounts: imageCheckCounts, imageCheckErrors } =
    useImageStatusCheck(id, parts);
  const { elements: interactiveElements } = useAppSelector(
    (state) => state.interactiveElement
  );

  useEffect(() => {
    dispatch(resetError());
    dispatch(resetStory());
    dispatch(resetStoryParts());
  }, [dispatch]);

  const storyInspirations = [
    {
      icon: <FaDragon className="text-orange-500" />,
      text: "A friendly dragon who can't breathe fire",
    },
    {
      icon: <FaTree className="text-green-500" />,
      text: 'A magical treehouse that travels through time',
    },
    {
      icon: <FaGuitar className="text-purple-500" />,
      text: 'A group of talking animals who start a band',
    },
    {
      icon: <FaSeedling className="text-lime-500" />,
      text: 'A child who discovers they can talk to plants',
    },
  ];

  const handleGenerateStory = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsAnimating(true);
    const result = await dispatch(generateStory({ prompt }));
    if (generateStory.fulfilled.match(result)) {
      const storyPartId = result.payload.storyPartId;
      await dispatch(fetchStoryPartInteractiveElements(storyPartId));
    }
    setIsAnimating(false);
    if (!error) {
      confetti({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  };

  const handleContinueStory = async (continuationPrompt: string) => {
    setIsAnimating(true);
    const result = await dispatch(
      continueStory({ continuationPrompt, storyId: id })
    );
    if (continueStory.fulfilled.match(result)) {
      const storyPartId = result.payload.storyPartId;
      await dispatch(fetchStoryPartInteractiveElements(storyPartId));
    }
    setIsAnimating(false);
    if (!error) {
      confetti({
        particleCount: 50,
        spread: 70,
        origin: { y: 0.6 },
      });
    }
  };

  const handleResetStory = () => {
    dispatch(resetStory());
    dispatch(resetStoryParts());
    setPrompt('');
  };

  return (
    <div className="max-w-4xl mx-auto mt-8 p-6 bg-gradient-to-br from-purple-100 to-blue-100 rounded-lg shadow-lg relative pb-20">
      <h2 className="text-4xl font-bold mb-6 text-purple-600 text-center">
        Magical Story Creator
      </h2>
      {parts.length === 0 ? (
        <div className="space-y-8">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-purple-500 text-center">
              Story Inspiration
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {storyInspirations.map((inspiration, index) => (
                <div
                  key={index}
                  className="flex items-center p-3 bg-gradient-to-r from-purple-50 to-blue-50 rounded-lg transition-transform duration-300 hover:scale-105 cursor-pointer"
                  onClick={() => setPrompt(inspiration.text)}
                >
                  <div className="text-3xl mr-3">
                    {inspiration.icon}
                  </div>
                  <p className="text-gray-700">{inspiration.text}</p>
                </div>
              ))}
            </div>
          </div>
          <form
            onSubmit={handleGenerateStory}
            className="bg-white p-6 rounded-lg shadow-md"
          >
            <div className="mb-4">
              <label
                htmlFor="prompt"
                className="block text-xl font-medium text-purple-700 mb-2"
              >
                What's your magical story about?
              </label>
              <textarea
                id="prompt"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                className="w-full px-4 py-3 text-lg text-gray-700 border-2 border-purple-300 rounded-lg focus:outline-none focus:border-purple-500 transition duration-300"
                rows={4}
                placeholder="Once upon a time in a land of magic..."
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-gradient-to-r from-purple-500 to-indigo-600 text-white text-xl font-bold py-3 px-6 rounded-lg hover:from-purple-600 hover:to-indigo-700 transition duration-300 transform hover:scale-105 relative overflow-hidden"
              disabled={isLoading || isAnimating}
            >
              <span className="relative z-10">
                {isLoading || isAnimating
                  ? 'Creating Magic...'
                  : 'Start Your Magical Journey'}
              </span>
              {isAnimating && (
                <div className="absolute inset-0 flex items-center justify-center bg-purple-100 bg-opacity-75 rounded-lg z-20">
                  <div className="text-6xl animate-bounce">✨</div>
                </div>
              )}
            </button>
          </form>
        </div>
      ) : (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <StoryContent
            storyParts={parts}
            title={title}
            audioStatus={audioStatus}
            imageStatus={imageStatus}
            audioCheckErrors={audioCheckErrors}
            imageCheckErrors={imageCheckErrors}
            checkCounts={{ ...audioCheckCounts, ...imageCheckCounts }}
            interactiveElements={interactiveElements}
          />
          <ContinuationForm
            onSubmit={handleContinueStory}
            isLoading={isLoading}
          />
        </div>
      )}

      {error && (
        <p className="text-red-500 mb-4 text-center bg-red-100 p-3 rounded-lg mt-4">
          {error}
        </p>
      )}

      {parts.length > 0 && (
        <button
          onClick={handleResetStory}
          className="fixed bottom-8 right-8 bg-red-500 text-white font-bold py-2 px-4 rounded-full hover:bg-red-600 transition duration-300 transform hover:scale-110"
        >
          Start New Story
        </button>
      )}
    </div>
  );
}

export default StoryGenerator;
