import React from 'react';
import { motion } from 'framer-motion';
import { useAppSelector } from '../../hooks/reduxHooks';
import { FaBook, FaMagic, FaTrophy } from 'react-icons/fa';

const UserStats: React.FC = () => {
  const { wordsGenerated, storyCount, achievements } = useAppSelector((state) => state.user);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
      <StatCard icon={FaBook} title="Stories Created" value={storyCount} />
      <StatCard icon={FaMagic} title="Words Woven" value={wordsGenerated} />
      <StatCard icon={FaTrophy} title="Achievements" value={achievements?.length || 0} />
    </div>
  );
};

const StatCard: React.FC<{ icon: React.ElementType; title: string; value: number }> = ({ icon: Icon, title, value }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    className="bg-white p-4 rounded-lg shadow-md flex items-center"
  >
    <Icon className="text-purple-600 text-3xl mr-4" />
    <div>
      <h4 className="text-lg font-semibold text-gray-800">{title}</h4>
      <p className="text-2xl font-bold text-purple-600">{value}</p>
    </div>
  </motion.div>
);

export default UserStats;