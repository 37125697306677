import React from 'react';
import { Link } from 'react-router-dom';
import { FaTwitter, FaFacebook, FaInstagram, FaGithub } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gradient-to-r from-purple-600 to-purple-800 text-white py-8 md:py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div className="text-center md:text-left">
            <h3 className="text-2xl font-bold mb-4">StoryBuddyAI</h3>
            <p className="text-sm">Where Imagination Comes to Life!</p>
            <p className="text-sm mt-2">Created with ❤️ for little dreamers everywhere</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/" className="hover:text-purple-200 transition duration-300">Home</Link></li>
              <li><Link to="/about" className="hover:text-purple-200 transition duration-300">About Us</Link></li>
              <li><Link to="/faq" className="hover:text-purple-200 transition duration-300">FAQ</Link></li>
              <li><Link to="/contact" className="hover:text-purple-200 transition duration-300">Contact</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Legal</h4>
            <ul className="space-y-2">
              <li><Link to="/terms" className="hover:text-purple-200 transition duration-300">Terms of Service</Link></li>
              <li><Link to="/privacy" className="hover:text-purple-200 transition duration-300">Privacy Policy</Link></li>
              <li><Link to="/cookies" className="hover:text-purple-200 transition duration-300">Cookie Policy</Link></li>
            </ul>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-4">Connect With Us</h4>
            <div className="flex justify-center md:justify-start space-x-4">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="hover:text-purple-200 transition duration-300">
                <FaTwitter size={24} />
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="hover:text-purple-200 transition duration-300">
                <FaFacebook size={24} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="hover:text-purple-200 transition duration-300">
                <FaInstagram size={24} />
              </a>
              <a href="https://github.com" target="_blank" rel="noopener noreferrer" className="hover:text-purple-200 transition duration-300">
                <FaGithub size={24} />
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-purple-400 text-center text-sm">
          <p>&copy; {new Date().getFullYear()} StoryBuddyAI. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
